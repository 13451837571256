import React from 'react';

const AwardContnet = [
{
  img: 'img',
    awardName: 'Спикер',
    awardFor: ' VII Красноярская научно-практическая конференция «Современный детский сад: ребенок в едином      образовательном   пространстве» Мастер-класс по теме «Разговор о важном через кейсы: ценности и смыслы» - 2024 ',
    href: 'https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html',
    delayAnimation: '0',
},
{
  img: 'img_1',
  awardName: ' Автор',
  awardFor: ' Федеральный  ресурсный центр передового педагогического опыта дошкольного образования Воспитатели России – публикация двух практик, представленных на Фестиваль «Авангард детства» 2024 г',
  href: 'https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html',
  delayAnimation: '0',
},
{
  img: 'img_2',
  awardName: 'Спикер',
  awardFor: ' VIIl Красноярского педагогического марафона  «От базовых результатов к результатам высоких достижений»   Муниципальный этап. Мастер-класс по теме  «Разговор о важном через кейс-метод» – 2024 г',
  href: 'https://www.facebook.com/pg/eppmin/posts/',
  delayAnimation: '400',
},
{
  img: 'img_4',
  awardName: 'Победитель',
  awardFor: 'Грантового конкурса социальных проектов «Моя малая родина – Балахтинский район»  Тема: «Разговор о важном через кейс-метод» 2024 г ',
  href: 'https://konkurs.rybakovfond.ru/',
  delayAnimation: '200',
},
{
  img: 'img_20',
      awardName: 'Спикер',
    awardFor: ' Всероссийской научно-практической НЕконференции  «Логопедическое сопровождение лиц с ограниченными возможностями здоровья» - 2024 ',
    href: 'https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html',
    delayAnimation: '0',
},
{
  img: 'img_17',
  awardName: 'Финалист',
  awardFor: 'регионального этапа  Всероссийского  конкурса профессионального мастерства  «Учитель-дефектолог года – 2023» в номинации "Учитель-логопед"',
  href: 'https://www.facebook.com/pg/eppmin/posts/',
  delayAnimation: '400',
},
  {
  img: 'img_18',
  awardName: 'Ведущий',
  awardFor: 'семинара по теме "Применение методики «Корабельный совет», как эффективного способа обсуждения и принятия решений, в вопросах взаимодействия специалистов сопровождения"',
  href: 'https://www.facebook.com/pg/eppmin/posts/',
  delayAnimation: '400',
},
{
  img: 'img_5',
    awardName: '2, 3 места',
  awardFor: 'Регионального этапа конкурса «Воспитатели России» –  2023 г, 2024 г',
  href: 'https://www.facebook.com/pg/eppmin/posts/',
  delayAnimation: '400',
},
{
  img: 'img_6',
  awardName: 'Победитель',
  awardFor: 'Краевой фестиваль лучших инклюзивных практик «Комплексное психолого-педагогическое сопровождение семей и детей в условиях инклюзивного образования (2021 г.)',
  href: 'https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html',
  delayAnimation: '0',
},
{
  img: 'img_9',
  awardName: ' Организатор региональной школы для специалистов ДОУ ',
  awardFor: ' Комплексность-Взаимодействие-Сопровождение-Успех» - 2022 г',
  href: 'https://konkurs.rybakovfond.ru/',
  delayAnimation: '200',
},
{
  img: 'img_7',
  awardName: 'Активный участник',
  awardFor: 'Летней онлайн-школы и Лидерской школы от Университет детства',
  href: 'https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html',
  delayAnimation: '0',
},
{
  img: 'img_16',
  awardName: 'Лауреат',
  awardFor: 'Регионального этапа конкурса  "Воспитатель года" - 2022 г',
  href: '#',
  delayAnimation: '400',
},
{
  img: 'img_10',
      awardName: 'Победитель I степени',
    awardFor: 'Международного фестиваля «Дети радуги: социализация и развитие коммуникативных способностей» (2021 г)',
    href: 'https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html',
    delayAnimation: '0',
},
{
  img: 'img_11',
      awardName: 'Трёхкратный победитель',
    awardFor: 'Международного  Конкурса стипендий и грантов имени Л. С. Выготского (2021, 2019 и 2017 г)',
    href: 'https://konkurs.rybakovfond.ru/',
    delayAnimation: '200',
},
{
  img: 'img_12',
  awardName: 'Автор',
  awardFor: ' Региональный атлас образовательных практик Красноярского края – представление пяти практик  (2019, 2021, 2022, 2024 г.)',
  href: '#',
  delayAnimation: '400',
},
{
  img: 'img_14',
      awardName: 'Спикер',
    awardFor: 'Народного Университета Детства. Тема: «Кейс-технология - инновационная образовательная модель»  г. Москва, 2020',
    href: 'https://www.facebook.com/pg/eppmin/posts/',
    delayAnimation: '400',
},
  {
  img: 'img_19',
      awardName: 'Выпускник',
    awardFor: 'Акселератора  АСИ',
    href: 'https://www.facebook.com/pg/eppmin/posts/',
    delayAnimation: '400',
},
  {
    img: 'img_13',
    awardName: 'Эксперт',
    awardFor: 'Краевого педагогического Хакатона - 2022',
    href: 'https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html',
    delayAnimation: '0',
  },
  {
    img: 'img_8',
    awardName: 'Участник',
    awardFor: 'Всероссийский творческий  конкурс-фестиваль педагогических работников «Виват, таланты»',
    href: 'https://konkurs.rybakovfond.ru/',
    delayAnimation: '200',
  },
];

const Awards = () => {
  return (
    <>
      <div className='row'>
        {AwardContnet.map((val, i) => (
          <div
            className='col-lg-6 m-15px-tb'
            key={i}
            data-aos='fade-right'
            data-aos-duration='1200'
            data-aos-delay={val.delayAnimation}
          >
            <div className='feature-box-02 d-flex align-items-center'>
              <div className='hicon'>
                <img src={`img/award/${val.img}.png`} alt='award' />
              </div>
              <div className='media-body'>
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p>
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
