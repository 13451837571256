import React from "react";

const OVajnomContent = [
  {
    icon: "icon-profile-female",
    title: "Развитие практики",
    descriptions: `В 2019 г. проект «Кейс-технология как инновационная образовательная модель, 
направленная на приобретение детьми с тяжёлыми речевыми нарушениями новых 
коммуникативных компетенций» опубликован в РАОП и получил статус «претендует 
на высший уровень». 
С того времени в практике произошли значительные изменения, главное из которых – 
мы не только развиваем коммуникативные компетенции, но выходим через 
погружение в кейсы  на ценности и смыслы,  простраиваем новые связи, налаживаем 
партнёрство. 
Практика была представлена на площадках различного уровня и получила высокие 
экспертные оценки`,
    delayAnimation: "600",
  },
  {
    icon: "icon-profile-female",
    title: "Экспертные оценки, подтверждающие значимость практики",
    descriptions: ` 
   - Федеральный  ресурсный центр передового педагогического опыта дошкольного 
образования Воспитатели России – публикация практики, представленной на 
Фестиваль «Авангард детства» - 2024 г
-  Конкурс социальных проектов «Моя малая Родина – Балахтинский район» Победитель - 2024
 - VII Красноярская научно-практическая конференция  «Современный детский сад: ребенок в едином   образовательном   пространстве» Мастер-класс - 2024 г
Международный  Конкурс стипендий и грантов имени Л. С. Выготского – победитель - 2021
- Краевой фестиваль лучших  инклюзивных практик  победитель - 2021 - 
Международный фестиваль «Дети радуги: социализация и развитие 
коммуникативных способностей» в номинации «Инновации в области социально
коммуникативного развития детей с ОВЗ  в детском саду и школе»  победитель - 2021 г 
- Журнал  «Логопед»  - Статья  в № 9, 2019.  Речевой проект для старших  дошкольников». Номинация  «Лучшее авторское пособие» Кейс «Дружная семья»
`,
    delayAnimation: "600",
  },
  {
    icon: "icon-circle-compass",
    title: "Цель",
    descriptions: `Создание условий для формирования у детей ценностей, социальных навыков и
        коммуникативных компетенций через осознанное взаимодействие посредством
        кейсовых ситуаций`,
    delayAnimation: "",
  },
  {
    icon: "icon-happy",
    title: "Основная идея",
    descriptions: `Основная идея практики заключается в создании условий для 
формирования у детей осознанных ценностей, социальных навыков и 
коммуникативных компетенций через совместное проживание реальных или 
вымышленных проблемных ситуаций. Этот подход включает несколько ключевых 
принципов:`,
    delayAnimation: "",
  },
  {
    icon: "icon-lightbulb",
    title: "Проблематика",
    descriptions: `Погружение в проблемную ситуацию: дети сталкиваются с ситуациями, которые 
могут возникнуть в реальной жизни, что позволяет им лучше понять окружающий мир 
и себя в нем.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-key",
    title: " Самостоятельность в решении проблем:",
    descriptions: `Проблемная ситуация должна быть 
интересной ребенку, соответствовать его возможностям и не содержать прямых 
подсказок. Это стимулирует самостоятельное мышление и поиск решений»`,
    delayAnimation: "400",
  },
  {
    icon: "icon-hotairballoon",
    title: "Роль педагога:",
    descriptions: `Взрослый занимает позицию равноправного партнера, который 
поддерживает процесс обсуждения, задает вопросы и помогает детям выражать свое 
мнение. Важно, чтобы все версии и идеи принимались без критики, хотя разногласия 
могут фиксироваться" `,
    delayAnimation: "800",
  },

  {
    icon: "icon-profile-female",
    title: "Деятельностный подход",
    descriptions: ` Дети сами участвуют в процессе исследования, приходят к 
своим выводам и осмысливают ценностные аспекты через игровую деятельность и 
кейсы, а не через навязанные правила`,
    delayAnimation: "600",
  },
  {
    icon: "icon-profile-female",
    title: "Интерактивное взаимодействие",
    descriptions: `Проект также направлен на развитие умения детей 
работать в команде, слушать друг друга, находить компромиссные решения и 
отстаивать свою точку зрения`,
    delayAnimation: "600",
  },
  {
    icon: "icon-profile-female",
    title: "Практическая реализация",
    descriptions: ` Практика включает создание материалов для кейсов, 
включая игрушки и элементы обстановки, что способствует развитию творческих 
способностей и вовлечению студентов с ограниченными возможностями здоровья 
(ОВЗ), родителей и школьников начальных классов`,
    delayAnimation: "600",
  },
  {
    icon: "icon-profile-female",
    title: "Суть практики",
    descriptions: `Суть практики состоит в комплексном подходе к воспитанию и 
обучению детей через активное вовлечение их в процесс решения жизненных задач, 
где они учатся осознавать ценности, развивать социальные и коммуникативные 
компетенции, работая в сотрудничестве с другими участниками образовательного 
процесса.`,
    delayAnimation: "600",
  },
];
export default function OVajnom() {
  return (
      <>
        <div className="row">
          {OVajnomContent.map((val, i) => (
              <div
                  className="col-md-6 col-lg-6 my-3"
                  key={i}
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay={val.delayAnimation}
              >
                <div className="feature-box-01">
                  <div className="icon">
                    <i className={`icon ${val.icon}`}></i>
                  </div>
                  <div className="feature-content">
                    <h5>{val.title}</h5>
                    <p style={{ whiteSpace: "pre-line" }}>{val.descriptions}</p>
                  </div>
                </div>
                {/* End .feature-box-01 */}
              </div>
          ))}
        </div>
      </>
  );
}
