import React from "react";
import TextLoop from "react-text-loop";

const conctInfo = {
  phone: "+7 913-175-2755",
  email: "allazinareva@mail.ru",
};

const sliderContent = {
  name: "Алла Зинарева",
  designation: "Учитель-логопед",
  description: `Мои воспитанники – уникальные маленькие звёздочки, искренние,  интересные, любознательные.
Самое главное – не угасить их веру в себя и в людей, не обесцветить  радость открытий, не погасить огонёк творчества.
А для этого я и сама должна слышать голос детства в своём сердце.
"Будь терпелив в ожидании чуда и будь готов для встречи с ним в ребёнке" Ш. А. Амонашвили`,
  btnText: " Связаться со мной",
};

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="mailto:allazinareva@mail.ru">
              {conctInfo.email}
            </a>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  Добрый день, меня зовут
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div className="row">
                  <div className="col-12">
                    {/*<div*/}
                    {/*  data-aos="fade-up"*/}
                    {/*  data-aos-duration="1200"*/}
                    {/*  data-aos-delay="200"*/}
                    {/*>*/}
                      <TextLoop>
                        <p className="loop-text lead"> Учитель-логопед</p>
                      </TextLoop>
                    {/*</div>*/}
                  </div>
                </div>

                <p
                  className="desc"
                  style={{ whiteSpace: "pre-line" }}
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-white"
                    href="#contactus"
                  >
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/slider/banner.jpg"
            })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
