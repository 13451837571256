import React from "react";
import Slider from 'react-slick';
import YouTube from 'react-lazyload-youtube';

const photos = [
  {
    img: "campus/1.jpg",

    delayAnimation: "",
  },
  {
    img: "campus/2.jpg",

    delayAnimation: "",
  },
  {
    img: "campus/3.jpg",

    delayAnimation: "",
  },
  {
    img: "campus/4.jpg",

    delayAnimation: "",
  },
  {
    img: "campus/5.jpg",

    delayAnimation: "",
  },
  {
    img: "campus/6.jpg",

    delayAnimation: "",
  },
  {
    img: "campus/7.jpg",

    delayAnimation: "",
  },
  {
    img: "campus/1.png",

    delayAnimation: "",
  },
  {
    img: "campus/2.png",

    delayAnimation: "",
  },
];

const asi = [
  {
    img: "asi/1.png",
    delayAnimation: "",
  },
  {
    img: "asi/2.png",
    delayAnimation: "",
  },
  {
    img: "asi/1.png",
    delayAnimation: "",
  },
  {
    img: "asi/2.png",
    delayAnimation: "",
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const opts = {
  width: '500',
  height: '281',
  playerVars: {},
};

const _onReady = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};

const Community = () => {
  return (
    <>
      <section id="community">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 ">
              <p>Фонд <a href="https://universityofchildhood.ru">«Университет Детства»</a> объединяет увлеченных
                педагогов, способных искать, находить и применять лучшие педагогические практики, ориентированные на
                ребенка.</p>
              <p>Для меня очень важно не
                только постоянно самосовершенствоваться, обучаясь новому, но и создавать условия для объединения
                активных, готовых меняться и профессионально расти педагогов и управленцев не только городов, но и
                сельских территорий края. </p>
              <p>Верю, что, предоставляя взрослым возможности для развития, мы создаем условия для счастливого
                детства.</p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 ">
              <h4>Кампус Красноярского края сообщества «Университет детства»</h4>
              <p>Организатор Кампуса Красноярского края сообщества «Университет
                детства» (2020г.) В нашем сообществе единомышленников, людей, ищущих и развивающихся, смелых и
                творческих, каждый участник чувствует свой опыт востребованным, находит поддержку, чтобы полнее раскрыть
                и проявить себя — именно благодаря таким людям и происходят позитивные изменения в дошкольной среде.</p>
              <p>Что даёт профессиональное сообщество Кампуса? </p>
              <p>Поддержку, поле для реализации идей, ощущение "плеча", обратную связь, возможности объединения и
                генерации идей.</p>
              <p>Мы с активными коллегами постоянно проводим активности для педагогов, где можно:</p>
              <ul>
                <li>познакомиться и выбрать новые технологии</li>
                <li>получить новые инструменты, «практику без воды»</li>
                <li>получить пространство для нетворкинга, обмена опытом, презентации себя, своего опыта на край</li>
                <li>получить помощь при подготовке к презентации себя в новых форматах</li>
              </ul>
              <p>Ссылки на страницу кампуса: </p>
              <p><a href="https://vk.com/club201274705/" target="_blank"
                    rel="noopener">https://vk.com/club201274705</a></p>
              <p><a href="https://www.facebook.com/CumpusUDKk" target="_blank"
                    rel="noopener">https://www.facebook.com/CumpusUDKk</a></p>
              <Slider {...settings}>
                {photos.map((val, j) => (
                  <div
                    key={j * 100}
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay={val.delayAnimation}
                  >
                    <div className="media">
                      <img className="slick-img"
                           src={`img/${val.img}`}
                           alt="review"
                      ></img>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-12">
              <h4>Акселератор «Агентство стратегических инициатив»</h4>
              <p><a href={'https://asi.ru/leaders/lead-projects/'} target="_blank">Акселератор «Агентство стратегических инициатив»</a> – «агент изменений», работающий
                вместе с лидерами над масштабными инициативами и объединяющий усилия
                общества, бизнеса и государства.
                По итогам отбора проектов в Осенний Акселератор 2021 наш проект «Клуб активных педагогов-дошкольников
                Красноярского края – меняем дошкольное образование» прошёл в акселератор, и команду проекта из шести
                человек пригласили к дальнейшей работе в рамках обучающей программы развития. В течение полутора месяцев
                команда работала с трекерами и экспертами Агентства над системным развитием проекта и проработке
                траектории дальнейшего развития и масштабирования. По итогам работы создана брошюра, где размещён и наш
                проект</p>
              <p><a href={'https://vk.com/wall-201274705_359'} target="_blank"
                    rel="noopener">Анонс проекта"</a></p>
              <p><a href="https://vk.com/club201274705/" target="_blank"
                    rel="noopener">Ссылка на публикацию</a></p>
              <Slider {...settings}>
                {asi.map((val, j) => (
                  <div
                    key={j * 100}
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay={val.delayAnimation}
                  >
                    <div className="media">
                      <img className="slick-img"
                           src={`img/${val.img}`}
                           alt="review"
                      ></img>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="mt-5 col-6 offset-3">
            <p className="text-center">Видео о проекте</p>
              <YouTube
                videoId={'P1TjlLkXkSo'} opts={opts} onReady={_onReady}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Community;
