import React from "react";

const ServiceContent = [
  {
    icon: "icon-circle-compass",
    title: "Основная идея",
    descriptions: `Карта приключений это – визуализированные детьми изображения, которыми дети самостоятельно через шаги обозначают актуальные задания и упражнения, так называемый индивидуальный образовательный маршрут, создаваемый самим ребёнком, предоставляющий возможность работы по преодолению речевых нарушений через шаги-задачи.
В связи с тем, что у детей тяжёлые нарушения речи, им сложно формулировать свои мысли. С помощью карты приключений можно простроить индивидуальную траекторию развития дошкольника с опорой на его сильные стороны, природные склонности и способности, предоставив ребёнку возможность самому поставить цель занятия, оречевить её и визуализировать путь к этой цели в виде маленьких задач-шагов, которые он сам озвучит и схематично нарисует.`,
    delayAnimation: "",
  },
  {
    icon: "icon-happy",
    title: "Субъектность и осознанность",
    descriptions: `К решению проблемы своих речевых нарушений ребёнок может прийти с помощью ситуаций или приключений, придуманных им самим.
Процесс работы над правильной речью превращается в увлекательное путешествие, состоящее из педагогических ситуаций совместного с детьми планирования программы собственного развития ребенка в игре, общении, обучении.
Для того, чтобы понять цели и задачи своего путешествия, ребёнок ищет ответы на вопросы: «Куда я сегодня отправлюсь? Какой маршрут придумаю? Над каким звуком будем работать? Что для этого нужно сделать? Что нам для этого понадобится? Какую дорогу можно проложить к этому звуку?..»`,
    delayAnimation: "",
  },
  {
    icon: "icon-lightbulb",
    title: "Проблематика",
    descriptions: `Важно, что в процессе деятельности ребёнок включён в активный творческий поиск, понимает, к чему хочет прийти. Дети актуализируют и обобщают имеющиеся знания по данной проблеме, ставят перед собой задачу, которую разбивают на маленькие шаги.
В процессе путешествия дети проходят этапы логопедического занятия: делают артикуляционную гимнастику, отрабатывают звук, автоматизируют его в слогах, словах, предложениях…`,
    delayAnimation: "200",
  },
  {
    icon: "icon-key",
    title: "Педагог",
    descriptions: `Важно поощрять ребёнка рассказывать о том, что он делает, почему рисует или выкладывает именно это, Детям это интересно, соответствуют зоне актуального и ближайшего развития.
Детям нравится всё самим рисовать, вырезать, наклеивать на карту, поэтому желательно приготовить больше разнообразного материала для того, чтобы ребёнок имел выбор и возможность украшать свой путь на карте.
Важно, чтобы в работе по карте принимали участие педагоги и родители. Педагог занимает позицию равного партнёра, совместно проживает с детьми события, задаёт вопросы и побуждает детей к высказыванию своего мнения. Дети применяют полученные знания, переводя их в реальность.
Важно правильно формулировать вопросы, содержащие мотивацию и выход на решение проблемы.`,
    delayAnimation: "400",
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-6 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p style={{ whiteSpace: "pre-line" }}>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
