import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Есть вопросы? Вы можете связаться со мной по электронной почте</h4>

        <ul>
          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              МБДОУ Балахтинский детский сад № 5 «Солнышко».
            </span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-envelope"></i>

            <a href="mailto:allazinareva@mail.ru">
              <span>allazinareva@mail.ru</span>
            </a>
          </li>
          {/* End li */}

          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
