import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import Masonry from "react-masonry-css";

import YouTube from 'react-lazyload-youtube';

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const opts = {
  width: '500',
  height: '281',
  playerVars: {},
};

const tabList = [
  { title: "Нескучные будни", href: "Qp_NOUh9oYs", name: 'Я - лидер' },
  { title: "Нескучные будни", href: "JTMdFCh49EI", name: 'Моё хобби' },
  { title: "Нескучные будни", href: "jjm-7ab8q80", name: 'Тёплый подарок' },

];

const _onReady = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};

const Closer = () => {
  return (
      <div>
      <p>
        <a href="https://rutube.ru/video/private/66ec36571952d5bfa1343e62bd54ebd9/?p=SlpH4RSOW3gLJ-slT7aP8Q" target="_blank" rel="noreferrer noopener">Я лидер (видео)</a>
      </p>
      <p>
        <a href="https://rutube.ru/video/private/a8a1d3c670b4d3ef03edbc631c81d8ef/?p=Iq8eWPeUbDL5e8xJt9fDyA" target="_blank" rel="noreferrer noopener">Моё хобби (видео)</a>
      </p>
      <p>
        <a href="https://rutube.ru/video/private/03dbad756644285ac806d43b58d165f8/?p=6W-u7apkTvt-aq8yiBMdjQ" target="_blank" rel="noreferrer noopener">Тёплый подарок (видео)
        </a>
      </p>
      </div>
  );
};

export default Closer;
